@import 'fonts/all';
@import 'util/variables.module';
@import 'util/mixins';
@import 'util/normalize';

// NPM package imports
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import 'primereact/resources/primereact.min.css';
@import 'primereact/resources/themes/md-light-indigo/theme.css';

@import 'primereact/all'; // our overrides to their styles
@import 'assessor/all';

// Components
@import '../components/ui/Toolbar/style';
@import '../components/pages/AppTopbar/style';
