$button-colour-text: white;

.p-button {
  background-color: $colour-blue-600;
  color: $button-colour-text;

  &:enabled:hover {
    background-color: $colour-blue-700;
  }

  &:enabled:active {
    background-color: $colour-blue-800;
  }

  &:enabled:focus {
    background-color: $colour-blue-600;
    outline: $colour-blue-300 solid 2px;
  }
}

.p-button.p-button-secondary {
  background-color: $colour-green-600;
  color: $button-colour-text;

  &:enabled:hover {
    background-color: $colour-green-700;
  }

  &:enabled:active {
    background-color: $colour-green-800;
  }

  &:enabled:focus {
    background-color: $colour-green-600;
    outline: $colour-green-500 solid 2px;
  }
}

.p-button.p-button-danger {
  &:enabled:focus {
    outline: rgba(211, 47, 47, 0.4) solid 2px;
  }
}

.p-button.p-button-outlined {
  color: $colour-blue-600;

  &:enabled:hover {
    background-color: $colour-blue-50;
    color: $colour-blue-700;
  }

  &:enabled:focus {
    background-color: $colour-blue-100;
  }

  &:enabled:active {
    background-color: $colour-blue-100;
    color: $colour-blue-700;
  }
}

.p-button.p-button-secondary.p-button-outlined {
  color: $colour-green-600;

  &:enabled:hover {
    background-color: $colour-green-50;
    color: $colour-green-700;
  }

  &:enabled:focus {
    background-color: $colour-green-100;
  }

  &:enabled:active {
    background-color: $colour-green-100;
    color: $colour-green-700;
  }
}

.p-button.p-button-text {
  color: $colour-blue-600;

  &:enabled:hover {
    background-color: $colour-blue-50;
    color: $colour-blue-700;
  }

  &:enabled:focus {
    background-color: $colour-blue-100;
  }

  &:enabled:active {
    background-color: $colour-blue-100;
    color: $colour-blue-700;
  }
}

.p-button.p-button-secondary.p-button-text {
  color: $colour-green-600;

  &:enabled:hover {
    background-color: $colour-green-50;
    color: $colour-green-700;
  }

  &:enabled:focus {
    background-color: $colour-green-100;
  }

  &:enabled:active {
    background-color: $colour-green-100;
    color: $colour-green-700;
  }
}
