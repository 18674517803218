.p-tabview-title {
  @media screen and (max-width: 500px) and (min-width: 360px) {
    white-space: normal;
    max-width: 113px !important;
    display: block;
  }
}

.tabPanel-error-header a {
  color: #ff0000 !important;
}
