.datatable-borderless-noheader {
  border: none;
}

.datatable-borderless-noheader thead {
  display: none;
}

.datatable-borderless-noheader td {
  border: none;
}

@media (min-width: 640px) {
  .mobileExpander {
    display: none !important;
  }

  .desktopExpander {
    display: revert !important;
  }
}

@media (max-width: 639px) {
  .mobileExpander {
    display: revert !important;
  }

  .desktopExpander {
    display: none !important;
  }
}
