//growl
body .p-growl .p-growl-item-container.p-growl-message-error {
  background-color: $colour-red-500 !important;
  color: white !important;
  border-radius: 4px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
}

body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-image,
body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-icon-close {
  color: white !important;
}

body .p-growl .p-growl-item-container.p-growl-message-success {
  background-color: $colour-green-400 !important;
  color: white !important;
  border-radius: 4px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
}

body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-image,
body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-icon-close {
  color: white !important;
}
