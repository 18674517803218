.layout-footer {
  @include transition(margin-left $transition-duration);
  background-color: $colour-blue-1100;
  padding: 1em 2em;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  img {
    vertical-align: middle;
  }

  .footer-text {
    vertical-align: middle;
    color: white;
  }
}
