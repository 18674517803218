@font-face {
  font-family: 'Matter-Bold';
  src: url('./../fonts/Matter-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'Matter-Heavy';
  src: url('./../fonts/Matter-Heavy.otf') format('truetype');
}
@font-face {
  font-family: 'Matter-Light';
  src: url('./../fonts/Matter-Light.otf') format('truetype');
}
@font-face {
  font-family: 'Matter-Medium';
  src: url('./../fonts/Matter-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'Matter-Regular';
  src: url('./../fonts/Matter-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'Matter-SemiBold';
  src: url('./../fonts/Matter-SemiBold.otf') format('truetype');
}
@font-face {
  font-family: 'MatterSQ-Bold';
  src: url('./../fonts/MatterSQ-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'MatterSQ-Heavy';
  src: url('./../fonts/MatterSQ-Heavy.otf') format('truetype');
}
@font-face {
  font-family: 'MatterSQ-Light';
  src: url('./../fonts/MatterSQ-Light.otf') format('truetype');
}
@font-face {
  font-family: 'MatterSQ-Medium';
  src: url('./../fonts/MatterSQ-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'MatterSQ-Regular';
  src: url('./../fonts/MatterSQ-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'MatterSQ-SemiBold';
  src: url('./../fonts/MatterSQ-SemiBold.otf') format('truetype');
}
