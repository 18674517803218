/* Dashboard */

$dashboard-border-radius: 3px;
$dashboard-colour-secondary: $colour-grey-500;
$dashboard-colour-divider: $colour-grey-200;

.itemCard {
  width: 180px;
}

.selectedCard {
  background-color: lighten($colour-blue-600, 40%);
  padding: 1em;
  margin-bottom: 16px;
  border-radius: 4px;
}

.primary-heading {
  color: $colour-heading;
}

.dashboard {
  .summary {
    cursor: pointer;
    position: relative;
    border: 1px solid;
    min-height: 150px;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .detail {
      color: $dashboard-colour-secondary;
      display: block;
      font-size: 13px;
      margin-top: 10px;
    }

    .numbers {
      font-size: 40px;
      width: 100% !important;
      text-align: center;
      font-weight: bold;
      display: block;
    }

    .count {
      color: #ffffff;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 24px;
      padding: 7px 14px;
      @include border-radius($dashboard-border-radius);

      &.visitors {
        background-color: #20d077;
      }

      &.purchases {
        background-color: #f9c851;
      }

      &.revenue {
        background-color: #007be5;
      }
    }
  }

  .highlight-box {
    height: 100px;
    @include clearfix();

    .initials {
      height: 100%;
      float: left;
      width: 50%;
      text-align: center;
      padding: 1em;

      > span {
        font-size: 48px;
      }
    }

    .highlight-details {
      height: 100%;
      background-color: #ffffff;
      float: left;
      width: 50%;
      padding: 8px;

      i {
        font-size: 24px;
        vertical-align: middle;
        margin-right: 0.25em;
      }

      .count {
        color: $dashboard-colour-secondary;
        font-size: 36px;
        display: block;
      }
    }
  }

  .task-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0.5em 0.25em;
      border-bottom: 1px solid $dashboard-colour-divider;
      @include clearfix();
    }

    .p-checkbox {
      vertical-align: middle;
      margin-right: 0.5em;
    }

    .task-name {
      vertical-align: middle;
    }

    i {
      float: right;
      font-size: 24px;
      color: $dashboard-colour-secondary;
    }

    .p-panel-content {
      min-height: 256px;
    }
  }

  .contact-form {
    .p-panel-content {
      min-height: 256px;
    }
  }

  .contacts {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        border-bottom: 1px solid $dashboard-colour-divider;

        button {
          padding: 9px;
          width: 100%;
          box-sizing: border-box;
          text-decoration: none;
          position: relative;
          display: block;
          @include border-radius(2px);
          @include transition(background-color 0.2s);

          .name {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 18px;
          }

          .email {
            position: absolute;
            right: 10px;
            top: 30px;
            font-size: 14px;
            color: $dashboard-colour-secondary;
          }

          &:hover {
            cursor: pointer;
            background-color: #eeeeee;
          }
        }

        &:last-child {
          border: 0;
        }
      }
    }

    .p-panel-content {
      min-height: 256px;
    }
  }

  .activity-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      border-bottom: 1px solid $dashboard-colour-divider;
      padding: 16px 8px;

      .count {
        font-size: 24px;
        color: #ffffff;
        background-color: #007be5;
        font-weight: 700;
        display: inline-block;
        padding: 0.25em 0.5em;
        @include border-radius($dashboard-border-radius);
      }

      &:first-child {
        border-top: 1px solid $dashboard-colour-divider;
      }

      &:last-child {
        border: 0;
      }

      .p-g-6:first-child {
        font-size: 18px;
        padding-left: 0;
      }

      .p-g-6:last-child {
        text-align: right;
        color: $dashboard-colour-secondary;
      }
    }
  }
}
