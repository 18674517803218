.layout-main {
  @include transition(margin-left $transition-duration);
  padding: 66px 16px 45px 16px;
}

.tenant-code-button {
  padding-top: 10px;
  width: 50%;
}

@media (max-width: 639px) {
  .tenant-code-button {
    padding-top: 10px;
    width: 100%;
  }
}


