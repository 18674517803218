.form-container {
  flex: 1;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  //overflow-x: auto;
}

.form-header-el {
  border-top: 1px solid #777777;
  border-bottom: 1px solid #777777;
  padding-left: 21px !important;
  background-color: #eeeeee;
}

.form-label-el {
  border-bottom: 1px solid #dedede;
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.form-flex {
  display: flex;
}
.form-column-direction {
  // display: flex;
  flex-direction: column;
}
.relative {
  position: relative;
}
.form-row-direction {
  display: flex;
  flex-direction: row;
  flex: 1 !important;
}
.form-flex-1 {
  flex: 1;
}
.form-flex-0 {
  flex: 0;
}
.form-flex-end {
  flex: 1;
  justify-content: flex-end;
  padding: 0px;
}
.form-flex-0-end {
  flex: 0;
  justify-content: flex-end;
  padding: 0px;
}
.form-flex-start {
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 0px;
}
.form-group-clear {
  margin: 0 !important;
}
.form-border-bottom {
  border-bottom: 1px solid #dedede;
}
.form-no-padding {
  padding: 0 !important;
}
.form-checkbox-label {
  flex: 1;
  padding: 10px 21px;
  background-color: #eeeeee;
  margin: 0px;
  border-right: 1px solid #dedede;
}
.form-checkbox-container {
  flex-direction: column;
  flex: 1;
  display: flex;
}
.form-checkbox-option {
  flex: 1;
  border-bottom: 1px solid #dedede;
  margin: 0 !important;
  padding: 12px 15px;
  flex-direction: row;
  display: flex !important;
}
.form-date-detail {
  flex: 1;
  justify-content: flex-end;
  flex-direction: row;
}
.form-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.form-signature-img {
  width: 17%;
}
.form-signature-img-camera {
  width: 17%;
}
.form-signature-img-empty {
  width: 17%;
  height: 80px;
}
.form-signature-img-camera-empty {
  height: 80px;
  object-fit: contain;
}
.f-m-5 {
  margin: 5px 0px;
}
.m-10 {
  margin: 10px 10px 10px 0px;
  max-width: 100%;
  image-orientation: none;
}
