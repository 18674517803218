/*
 * Pallette
 */

// Greys - from Tailwind Gray pallette (https://tailwindcss.com/docs/customizing-colors#curating-colors)
$colour-grey-50: #fafafa;
$colour-grey-100: #f4f4f5;
$colour-grey-200: #e4e4e7;
$colour-grey-300: #d4d4d8;
$colour-grey-400: #a1a1aa;
$colour-grey-500: #71717a;
$colour-grey-600: #52525b;
$colour-grey-700: #3f3f46;
$colour-grey-800: #27272a;
$colour-grey-900: #18181b;

// Colours - HSL values based on Tailwind pallettes, 1100 is an extra just for Assessor
$colour-blue-50: #f0f6ff;
$colour-blue-100: #dbe9ff;
$colour-blue-200: #bfd8ff;
$colour-blue-300: #92bbfc;
$colour-blue-400: #5f9afa;
$colour-blue-500: #3b82f5;
$colour-blue-600: #2e75eb;
$colour-blue-700: #1c64d9;
$colour-blue-800: #1e56b0;
$colour-blue-900: #1e478a;
$colour-blue-1100: #102f4a;

$colour-green-50: #f0fcf7;
$colour-green-100: #dcfcef;
$colour-green-200: #bcf7df;
$colour-green-300: #86f0c4;
$colour-green-400: #49dea0;
$colour-green-500: #21c480;
$colour-green-600: #15a368;
$colour-green-700: #148053;
$colour-green-800: #166645;
$colour-green-900: #14543a;

$colour-red-500: #ed001d;
$colour-red-400: #ef6262;

/*
 * Global or shared colour values - if you want colours consistent between multiple components make variables.
 */

// General
$colour-body-background: $colour-grey-50;
$colour-body-text: $colour-grey-700;
$colour-heading: $colour-blue-1100;

$colour-link: $colour-green-600;
$colour-link-hover: $colour-green-500;

// Topbar
$colour-topbar-background-left: $colour-blue-1100;
$colour-topbar-background-right: $colour-blue-900;

/*
 * Animation
 */
$transition-duration: 0.1s;

/* 
 * Media query breakpoints
 */
$breakpoints-sm: 576px;
$breakpoints-md: 768px;
$breakpoints-lg: 992px;
$breakpoints-xl: 1200px;
$breakpoints-xxl: 1400px;

/*
 * Fonts
 */
$font-size-body: 14px;

$font-matter-bold: 'Matter-Bold';
$font-matter-heavy: 'Matter-Heavy';
$font-matter-light: 'Matter-Light';
$font-matter-medium: 'Matter-Medium';
$font-matter-regular: 'Matter-Regular';
$font-matter-semibold: 'Matter-SemiBold';
$font-mattersq-bold: 'MatterSQ-Bold';
$font-mattersq-heavy: 'MatterSQ-Heavy';
$font-mattersq-light: 'MatterSQ-Light';
$font-mattersq-medium: 'MatterSQ-Medium';
$font-mattersq-regular: 'MatterSQ-Regular';
$font-mattersq-semibold: 'MatterSQ-SemiBold';

/*
 * Z-index
 */
$z-index-app-topbar: 900;

/*
 * Export so JavaScript can import
 */
:export {
  breakpointsSm: $breakpoints-sm;
  breakpointsMd: $breakpoints-md;
  breakpointsLg: $breakpoints-lg;
  breakpointsXl: $breakpoints-xl;
  breakpointsXxl: $breakpoints-xxl;
}
