@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: $colour-blue-600;
  }
  40% {
    stroke: $colour-blue-700;
  }
  66% {
    stroke: $colour-blue-800;
  }
  80%,
  90% {
    stroke: $colour-blue-500;
  }
}
