// Comes from an old theme, and fixes some layout issues
body .p-inputtext {
  font-size: 14px;
  color: #333;
  background: #fff;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 3px;
}

.p-inputtext {
  &:enabled:focus {
    box-shadow: inset 0 0 0 1px $colour-blue-700, inset 0 0 0 1px $colour-blue-700, inset 0 0 0 1px $colour-blue-700,
      inset 0 0 0 1px $colour-blue-700;
  }
}
