@import 'util/variables.module';

$login-colour-header-background: $colour-blue-1100;
$login-colour-menu-item-badge: white;
$header-height: 56px;
$header-height-phone: 42px;

.backgroundStyle {
  width: 100%;
  height: 100%;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  filter: opacity(60%);
  background-image: url('../media/login-background.jpg');
  position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.loginDivTitle {
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.loginContainer {
  @media (min-width: 970px) {
    padding-left: 35% !important;
    padding-right: 20% !important;
  }

  @media (min-width: 1130px) {
    padding-left: 35% !important;
    padding-right: 30% !important;
  }

  @media (min-width: 1601px) {
    padding-left: 37% !important;
    padding-right: 33% !important;
  }

  @media (max-width: 969px) {
    padding-left: 25% !important;
    padding-right: 25% !important;
  }

  @media (max-width: 701px) {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }

  @media (max-width: 375px) {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
}

.loginDialog {
  // padding: 0px 0px 8px !important;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: $colour-body-background;
  font-family: Open Sans;
  font-size: 16px;
  color: #3e3e3e;
  // position: relative;
  //width: 35%;

  /* align middle, but override on screen height below */
  position: relative;
  top: calc(50vh - (/* height */ 420px / 2));
  // left: calc(50vw - (/* width */ 410px / 2));
  //top: 30%;
  //left: 35%;
}

/* When the screen gets very short, prevent the login dialog from going off the top */
// @media screen and (max-height: 550px) {
//   .loginDialog {
//     top: 220px;
//   }
// }

.brandingDivStyle {
  background-color: #007ad9;
  text-align: center;
}

.inputDivStyle {
  padding: 8px 14px;
}

.brandingImageStyle {
  height: 36px;
}

.submitRowStyle {
  height: 36px;
}

.emptyRowStyle {
  height: 18px;
}

.emptyRowStyle-big {
  height: 24px;
}

.emptyRowStyle-small {
  height: 10px;
}

.submitButtonDivStyle {
  text-align: center;
}

.inputStyle {
  font-family: Open Sans;
  font-size: 16px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: block;
  border: none;
  border-bottom: 1px solid #808080;
  width: 100%;
  background-color: white;
}

.labelStyle {
  color: $colour-body-text;
  font-size: 11px;
  margin-left: 2px;
}

.topRowStyle {
  height: 27%;
  width: 100%;
}

.submitButton {
  border: 1px solid;
  padding: 4px 16px;
}

.header {
  background-color: $login-colour-header-background;
  position: static;
  height: $header-height; // Need height so inner div doesn't expand it.
  flex: 0 0 $header-height; // Need height so flex calculates properly.
  padding: 0px 18px 0px 18px;
  z-index: 995; // Need this so UserContextMenuContent will sits above all other elements.

  img {
    width: 90px;
  }

  * {
    font-size: 25px;
    color: $colour-grey-300;
  }
}

.login-form {
  background-color: $login-colour-menu-item-badge;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  overflow-y: auto;
  height: 100%;

  @media (max-width: 969px) {
    width: 60%;
  }

  @media (max-width: 701px) {
    width: 100%;
  }
}

.login-form-logo {
  max-width: 100%;
  margin-bottom: 20px;
}

.layout-logo-img {
  width: 100%;
}

.login-img-bg {
  background-image: url('../media/login-background.jpg');
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;

  @media (max-width: 969px) {
    width: 40%;
  }

  @media (max-width: 701px) {
    width: 0%;
  }
}

.login-limiter {
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
}

.login-container-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: $login-colour-menu-item-badge;
}

.login-wrapper {
  width: 100%;
  height: 100%;
  background: $login-colour-menu-item-badge;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
}

.login-form-header {
  background-color: $colour-blue-1100;
  position: fixed;
  width: 100%;
  height: $header-height; // Need height so inner div doesn't expand it.
  flex: 1;

  @media (max-width: 375px) {
    height: $header-height-phone; // Need height so inner div doesn't expand it.
  }
}

.login-form-input-p {
  padding: 30vh 20% 0px 20%;

  @media (min-width: 1601px) {
    padding-left: 30%;
    padding-right: 30%;
  }

  @media (max-width: 1130px) {
    padding-left: 15%;
    padding-right: 15%;
  }

  @media (max-width: 969px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-height: 969px) {
    padding-top: 23vh;
  }

  @media (max-width: 701px) {
    padding-top: 15vh;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-height: 701px) {
    padding-top: 15vh;
  }

  @media (max-width: 375px) {
    padding-top: 10vh;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.login-form-title {
  font-size: 42px;
  color: $login-colour-header-background;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: left;
  width: 100%;
  display: block;

  @media (max-width: 375px) {
    font-size: 32px;
  }
}
.login-form-sub {
  font-size: 20px;
  color: $login-colour-header-background;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: left;
  width: 100%;
  display: block;
  margin-bottom: 40px;
}

.login-box {
  background-color: $login-colour-menu-item-badge;
}

.login-input-field {
  font-family: Open Sans;
  font-size: 24px;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 48px;
  display: block;
  border: none;
  border-bottom: 1px solid #808080;
  width: 100%;
  background-color: white;

  @media (max-width: 375px) {
    height: 38px;
    font-size: 16px;
  }
}

.login-label {
  color: $login-colour-header-background;
  font-size: 14px;
  margin-left: 2px;
  @media (max-width: 375px) {
    font-size: 12px;
  }
}

.login-btn {
  width: 100%;
  // height: 48px;
  font-size: 18px !important;
  margin-top: 12px;
  @media (max-width: 375px) {
    // height: 38px;
    font-size: 14px !important;
  }
}

.login-status-row {
  height: 64px;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
}

.login-error-msg {
  flex: 1;
  background-color: $login-colour-menu-item-badge;
  font-size: 18px;
}
