.CustomUpload {
  .p-button-text-icon-left:nth-child(2) {
    display: none;
  }

  .p-fileupload-content {
    min-height: 100px;
  }
}

@media (max-width: 639px) {
  .p-fileupload-row div {
    max-width: 120px;
    word-break: break-all;
  }
}
