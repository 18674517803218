.p-tabview-nav-link,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  text-decoration: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: $colour-blue-800;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
  background-color: $colour-blue-100;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: $colour-blue-800;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background-color: $colour-blue-50;
}
