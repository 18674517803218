.public-DraftStyleDefault-ltr {
  text-align: inherit;
}

.rdw-center-aligned-block {
  text-align: center !important;
}

.rdw-right-aligned-block {
  text-align: right !important;
}

.rdw-justify-aligned-block {
  text-align: justify !important;
  text-justify: inter-word;
  text-align-last: left;
  /* for IE9 */
  -ms-text-align-last: left;
}
