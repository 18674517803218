.rating-container,
.react-star-rating__root {
  vertical-align: middle;
  display: inline-block;
}
.rating-container .rating-stars:before,
.rating-container:before {
  content: attr(data-content);
}
.react-star-rating__star {
  width: 25px;
}
.react-star-rating__star #star-flat {
  fill: #c6c6c6;
}
.react-star-rating__star #star-flat:hover {
  fill: #ffa91b;
}
.react-star-rating__root {
  font-size: 2em;
}
.react-star-rating__root.rating-editing:hover {
  cursor: pointer;
}
.rating-container {
  position: relative;
  color: #e3e3e3;
  overflow: hidden;
}
.rating-container .rating-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #f5a71b;
  -webkit-transition: all 0.01s;
  -moz-transition: all 0.01s;
  transition: all 0.01s;
}
.react-rating-caption {
  font-size: 1.25em;
  vertical-align: middle;
  margin-right: 0.5em;
}
.rating-disabled .rating-container:hover {
  cursor: not-allowed;
}
.react-star-rating__size--sm {
  font-size: 1em;
}
.react-star-rating__size--md {
  font-size: 2em;
}
.react-star-rating__size--lg {
  font-size: 2.5em;
}
