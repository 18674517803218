.toolbar {
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: white !important;
  border: 0px !important;
  border-radius: 4px !important;
  color: $colour-heading;
}

.toolbar-heading {
  color: $colour-heading;
}
