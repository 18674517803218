.app-topbar {
  position: fixed;
  right: 0;
  z-index: $z-index-app-topbar;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 10px;

  @include linear-gradient-left($colour-topbar-background-left, $colour-topbar-background-right);
  color: white;
}

.app-topbar-menu button {
  background: none;

  span {
    font-size: 2em;
    line-height: 30px;
  }

  &:hover {
    color: $colour-blue-200;
  }
}
