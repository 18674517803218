* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: $font-matter-light !important;
  font-size: $font-size-body;
  color: $colour-body-text;
  background-color: $colour-body-background;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
}

/* Typography */
h1 {
  font-size: 50px;
  line-height: 60px;
  margin: 0;
  font-family: $font-matter-light;
}

h2 {
  font-size: 38px;
  line-height: 48px;
  margin: 0;
  font-family: $font-matter-light;
}

h3 {
  font-size: 30px;
  line-height: 40px;
  margin: 0;
  font-family: $font-matter-light;
}

h4 {
  font-size: 24px;
  line-height: 34px;
  margin: 0;
  font-family: $font-matter-light;
}

h5 {
  font-size: 22px;
  line-height: 24px;
  margin: 0;
  font-family: $font-matter-medium;
}

a {
  font-family: $font-matter-semibold;
  font-size: 18px;
  line-height: 26px;
  text-decoration: none;
  color: $colour-link;
}

p {
  font-family: $font-matter-light;
  font-size: 18px;
  line-height: 26px;
  color: $colour-grey-500;
}

a {
  span {
    font-size: 18px;
    line-height: 28px;
  }
}

li {
  font-size: 18px;
  line-height: 26px;
}

button {
  text-align: center;
  background-color: $colour-blue-900;
  color: white;
  border: 0;

  &:hover {
    background-color: darken($color: $colour-blue-900, $amount: 5);
  }

  &:active {
    background-color: lighten($color: $colour-blue-900, $amount: 10);
  }

  &:disabled {
    background-color: $colour-grey-400;
  }
  &:focus {
    box-shadow: none !important;
  }
}
