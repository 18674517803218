.card.card-w-title {
  margin-top: 16px;
}

.dataTableCointainter {
  margin-top: 16px;
  margin-bottom: 10px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.toolbarContent {
  padding-bottom: 0px !important;
  padding-top: 10px !important;
  padding-left: 0px;
  padding-right: 0px;
}

.footerContainer {
  @media (max-width: 375px) {
    padding-left: 33%;
  }
  @media (min-width: 376px) {
    padding-left: 35%;
  }
  @media (min-width: 501px) {
    padding-left: 40%;
  }
  @media (min-width: 997px) {
    padding-left: 45%;
  }
}

.dataTablePhoto {
  @media (max-width: 640px) {
    padding-left: 41%;
  }
  @media (min-width: 641px) {
    padding-left: 18%;
  }
  @media (min-width: 1200px) {
    padding-left: 25%;
  }
  @media (min-width: 1605px) {
    padding-left: 35%;
  }
}

.loading-text {
  display: block;
  background-color: $colour-grey-100;
  min-height: 60px;
  -webkit-animation: pulse 5s ease-in-out infinite;
  animation: pulse 5s ease-in-out infinite;
  text-indent: -99999px;
  overflow: hidden;
}

//fix row width issue responsive and scrollable on mobile device
.assessmentTableLayout {
  @media screen and (max-width: 40em) {
    min-width: 1550% !important;
  }
}

.traineeTableLayout {
  @media screen and (max-width: 40em) {
    min-width: 330% !important;
  }
}

.formTableLayout {
  @media screen and (max-width: 40em) {
    min-width: 660% !important;
  }
}

.panel-container {
  padding: 1em;
  margin-bottom: 16px;
}

.card {
  background-color: white;
  padding: 1em;
  margin-bottom: 16px;
  border-radius: 4px !important;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);

  &.card-w-title {
    padding-bottom: 2em;
  }

  h1 {
    margin: 1em 0 0.5em 0;
    border-bottom: 1px solid #d5d5d5;
    padding: 0.1em;
    font-size: 24px;

    &:first-child {
      margin: 0 0 0.5em 0;
    }
  }
}

.p-g {
  -ms-flex-wrap: wrap;
}

.img-preview-icon {
  width: 100%;
  cursor: pointer;
  min-width: 25px;
}

.button-approve {
  > span {
    margin-left: -5px;
  }
}

.p-toolbar-group-right-custom {
  justify-content: flex-end;
}

.w3-row:after {
  content: '';
  display: table;
  clear: both;
}

@-webkit-keyframes opac {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes opac {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
