.p-message {
  a {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
  }
}

// Not sure why these are prefixed with body, that feels unnecessary.

body .p-messages.p-messages-success {
  background-color: $colour-green-400 !important;
  color: white !important;
  border-radius: 4px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
}

body .p-messages.p-messages-success .p-messages-icon {
  color: white !important;
}

body .p-messages.p-messages-success .p-messages-close {
  background-color: $colour-green-400 !important;
  color: white !important;
}

body .p-messages.p-messages-error {
  background-color: $colour-red-500 !important;
  color: white !important;
  border-radius: 4px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
}

body .p-messages.p-messages-error .p-messages-icon {
  color: white !important;
}

body .p-messages.p-messages-error .p-messages-close {
  background-color: $colour-red-500 !important;
  color: white !important;
}

body .p-message-summary {
  font-size: $font-size-body;
  color: inherit;
}