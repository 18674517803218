.layout-sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  scrollbar-width: thin;
  @include transition(left $transition-duration);
  @include shadow(0 0 6px 2px rgba(0, 0, 0, 0.16));

  .layout-logo {
    margin: 24px 14px 0;
  }

  .menuitem-badge {
    display: inline-block;
    margin-left: 4px;
    font-size: 10px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: white;
    background-color: $colour-blue-600;
    @include border-radius(50%);
  }
}
