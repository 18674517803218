body .p-component {
  font-family: $font-matter-light;
}

body .p-link {
  font-family: $font-matter-light;
}

p .p-large {
  font-size: 22px;
  line-height: 32px;
}

.p-sm-1,
.p-sm-2,
.p-sm-3,
.p-sm-4,
.p-sm-5,
.p-sm-6,
.p-sm-7,
.p-sm-8,
.p-sm-9,
.p-sm-10,
.p-sm-11,
.p-sm-12,
.p-md-1,
.p-md-2,
.p-md-3,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-7,
.p-md-8,
.p-md-9,
.p-md-10,
.p-md-11,
.p-md-12,
.p-lg-1,
.p-lg-2,
.p-lg-3,
.p-lg-4,
.p-lg-5,
.p-lg-6,
.p-lg-7,
.p-lg-8,
.p-lg-9,
.p-lg-10,
.p-lg-11,
.p-lg-12,
.p-xl-1,
.p-xl-2,
.p-xl-3,
.p-xl-4,
.p-xl-5,
.p-xl-6,
.p-xl-7,
.p-xl-8,
.p-xl-9,
.p-xl-10,
.p-xl-11,
.p-xl-12 {
  padding: 0.5em !important;
}

.p-link.layout-profile-link {
  i,
  span {
    font-size: 18px;
    line-height: 28px;
  }
}

.p-link {
  span {
    font-size: 14px;
    line-height: 12px;
  }
}

ul {
  .p-dropdown-item {
    font-size: 16px;
    line-height: 28px;
    overflow-wrap: break-word;
    width: 100%;
  }
}

.p-column-title {
  width: 100% !important;
}

.p-paginator-right-content {
  margin-right: 10px;
}

//truncate text inside dropdown
.p-inputtext {
  text-overflow: ellipsis;
}

.p-tabview-panels {
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
}

.p-dropdown .p-dropdown-panel {
  top: 35px !important;
  max-width: 100% !important;
  left: 0px !important;
}
