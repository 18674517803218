.p-tag {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9rem;
}

.p-tag.p-tag-primary {
  background-color: $colour-blue-600;
  color: white;
}

.p-tag.p-tag-success {
  background-color: $colour-green-600;
  color: white;
}
