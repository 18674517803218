.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: $colour-body-text;

  &:hover {
    color: $colour-link;
  }
}

.p-breadcrumb ul li:last-child .p-menuitem-text,
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  font-family: $font-matter-regular;
  font-size: 15px;
  color: $colour-body-text;

  &:hover {
    color: $colour-link;
  }
}
