.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $colour-blue-600;
  background-color: $colour-blue-600;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $colour-blue-700;
  background-color: $colour-blue-700;
}

.p-checkbox-label {
  margin: 0 0 0 0.5rem;
  line-height: 1;
}

/*
 * Fix the checkbox tick size - not sure why but it's bigger on their demo.
 * Bootstrap is probably interferring with it - having 2 UI frameworks is bad.
 */
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #ffffff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 6px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 6px;
    height: 12px;
    border-color: #ffffff;
    transform: translate3d(0, -10px, 0) rotate(45deg);
  }
}
