$menu-colour-active-route: $colour-green-600;

// Menu light
$menuitemColor: $colour-blue-1100;
$menuitemHoverColor: $colour-green-600;
$menuitemAccountHoverColor: white;
$menuitemActiveColor: $colour-green-600;
$menuitemActiveBgColor: $colour-grey-100;
$menuitemBorderColor: $colour-grey-300;

// Menu dark
$menuitemDarkHoverColor: $colour-blue-500;
$menuitemDarkActiveColor: $colour-blue-500;
$menuitemDarkActiveBgColor: $colour-grey-800;
$menuitemDarkBorderColor: $colour-grey-700;

.layout-wrapper {
  padding-bottom: 50px;
  position: relative;
  min-height: 100vh;
  background-color: $colour-grey-100;
}

.layout-menu-container {
  padding-bottom: 120px;
}

.layout-menu {
  border-top: 1px solid $menuitemBorderColor;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 10px;

  li {
    a {
      cursor: pointer;
      position: relative;
      text-decoration: none;
      display: block;
      line-height: 18px;

      i {
        font-size: 18px;
        vertical-align: middle;
      }

      span {
        margin-left: 0.25em;
        vertical-align: middle;
        line-height: 18px;
        display: inline-block;
      }

      .menuitem-toggle-icon {
        position: absolute;
        top: 50%;
        right: 1em;
        margin-top: -9px;
      }

      &.router-link-active {
        font-weight: 700;
      }
    }

    span {
      cursor: pointer;
      position: relative;
      text-decoration: none;
      display: block;

      i {
        font-size: 18px;
        vertical-align: middle;
      }

      span {
        margin-left: 0.25em;
        vertical-align: middle;
        line-height: 18px;
        display: inline-block;
      }

      .menuitem-toggle-icon {
        position: absolute;
        top: 50%;
        right: 1em;
        margin-top: -5px;
      }

      &.router-link-active {
        font-weight: 700;
      }
    }

    &.active-menuitem {
      > a {
        .menuitem-toggle-icon {
          @include icon-override('\e933');
        }
      }

      > span {
        .menuitem-toggle-icon {
          @include icon-override('\e933');
        }
      }
    }
  }

  > li {
    > a {
      padding: 0.75em 1em;

      span {
        font-size: $font-size-body;
      }
    }

    > span {
      padding: 0.75em 1em;

      span {
        font-size: $font-size-body;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;

      li {
        a {
          cursor: pointer;
          padding: 0.75em 1em;
          border-top: 0 none;
          display: flex;

          span {
            font-size: $font-size-body;
          }
        }
      }
    }
  }
}

.layout-sidebar-light {
  background-color: white;

  .layout-menu {
    > li {
      > a {
        border-top: 0px solid $menuitemBorderColor;
      }
    }

    li {
      a {
        color: $menuitemColor;

        &.router-link-active {
          color: $menu-colour-active-route;
        }

        &:hover {
          color: $menuitemHoverColor;
        }
      }

      &.active-menuitem {
        > a {
          color: $menuitemActiveColor;
        }

        > span {
          color: $menuitemActiveColor;

          a {
            color: $menuitemActiveColor;
          }

          span {
            color: $menuitemActiveColor;
          }
        }
      }

      &.active-parent-menuitem {
        > span {
          background-color: $menuitemActiveBgColor;
          color: $menuitemActiveColor;

          a {
            color: $menuitemActiveColor;
          }

          span {
            color: $menuitemActiveColor;
          }
        }
      }

      > a {
        &.active-route {
          //  background-color: $menuitemActiveBgColor;
          color: $menuitemActiveColor;
        }
      }
    }
  }
}

.layout-sidebar-dark {
  @include linear-gradient($colour-grey-600, $colour-grey-700);

  .layout-menu {
    > li {
      > a {
        border-top: 1px solid $menuitemDarkBorderColor;
      }

      &:last-child {
        > a {
          border-bottom: 1px solid $menuitemDarkBorderColor;
        }
      }

      ul {
        background-color: $menuitemDarkActiveBgColor;
      }
    }

    li {
      a {
        color: white;

        &.router-link-active {
          color: $menu-colour-active-route;
        }

        &:hover {
          color: $menuitemDarkHoverColor;
        }
      }

      &.active-menuitem {
        > a {
          background-color: $menuitemDarkActiveBgColor;
          color: $menuitemDarkActiveColor;
        }
      }
    }
  }
}

.layout-profile {
  text-align: center;
  padding: 20px 0;

  img {
    width: 56px;
    margin: 10px;
  }

  .layout-profile-link {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0.75em;
    @include transition(color $transition-duration);

    i {
      display: inline-block;
      font-size: 16px;
      vertical-align: middle;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    @include transition-property(max-height);
    @include transition-duration(0.4s);
    @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

    &.layout-profile-expanded {
      max-height: 500px;

      .p-link {
        &:hover {
          background-color: $colour-blue-1100 !important;
          color: white !important;
        }
      }
    }

    li {
      a {
        width: 100%;
        padding: 1em;
        border: 0 none;
        border-radius: 0;
        cursor: pointer;
        float: left;
        @include transition(color $transition-duration);
        &:hover {
          background-color: $colour-blue-600;
          text-decoration: none;
          i {
            color: $menuitemAccountHoverColor;
          }
        }

        a {
          text-decoration: none;
        }
      }

      button {
        width: 100%;
        padding: 1em;
        border: 0 none;
        border-radius: 0;
        cursor: pointer;
        @include transition(color $transition-duration);

        &:hover {
          color: $menuitemHoverColor;
        }

        span {
          margin-left: 0.25em;
          vertical-align: middle;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.layout-sidebar-light {
  .layout-profile {
    .layout-profile-link {
      color: $menuitemColor;

      &:hover {
        color: $menuitemAccountHoverColor;

        span {
          color: $menuitemAccountHoverColor;
        }
      }
    }

    ul {
      background-color: $menuitemActiveBgColor;

      li {
        button {
          color: $menuitemColor;

          &:hover {
            color: $menuitemHoverColor;
          }
        }
      }
    }
  }
}

.layout-sidebar-dark {
  .layout-profile {
    .layout-profile-link {
      color: white;

      &:hover {
        color: $menuitemDarkHoverColor;
      }
    }

    ul {
      background-color: $menuitemDarkActiveBgColor;

      li {
        button {
          color: white;

          &:hover {
            color: $menuitemDarkHoverColor;
          }
        }
      }
    }
  }
}
